<template>
<div class="view-home">
  <div class="container questions-wrap pt-5">
    <div class="question-title">
      <h3 class="text-primary">{{ $t('identification.title') }}</h3>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="question-content">
          {{ $t('identification.hint1') }}
        </div>
        <div>
          {{ $t('identification.hint2') }}
        </div>
      </div>
      <div class="py-3 px-0 capture-buttons-wrap">
        <div class="float-left px-3" style="width: 230px">
          <div class="text-center">
            <div id="capture">
            </div>
            <canvas id="c" style="display: none" width="320" height="200"></canvas>
          </div>
          <div class="capture-wrap" :class="{'hidden': (cameraInitialized || loading)}">
            <img id="i" width="320" :src="takedImageSource" alt="" style="margin-left: -60px">
          </div>
          <div class="button-wrap">
            <button :class="{'hidden': takedScreenshot}" type="button" @click="savePhoto" class="takePhotoButton btn-block">
              <span>{{ $t('identification.take-photo-button') }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="requirements">
        <div class="card my-3">
          <h3>{{ $t('identification.requirements-title') }}</h3>
          <ol>
            <li>{{ $t('identification.requirement-1') }}</li>
            <li>{{ $t('identification.requirement-2') }}</li>
            <li>{{ $t('identification.requirement-3') }}</li>
            <li>{{ $t('identification.requirement-4') }}</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="row my-5">
      <div class="mx-auto">
<!--        <router-link to="/" class="btn btn-default"><i class="el-icon-arrow-left mr-2"></i>Назад</router-link>-->
        <a @click="nextPage" class="btn btn-primary">{{ $t('identification.next-button') }}</a>
      </div>
    </div>
  </div>
  <b-modal id="error-modal" hide-footer hide-header>
    <div id="modal-wrapper">
      <div id="dialog" class="py-3">
        <button @click="$bvModal.hide('error-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <img class="modal-warning-img mb-3" :src="modalImg">
        <h3 class="mb-0">{{ modalMessage }}</h3>
        <div class="clearfix">
          <p>{{ modalDesc }}</p>
          <a v-if="!modalSuccess" @click="reset" class="btn btn-primary mx-2">Попробуйте еще раз</a>
          <a v-if="!modalSuccess" @click="$bvModal.hide('error-modal')" class="btn btn-outline-primary mx-2">Отменить</a>
          <a v-if="modalSuccess" @click="nextPage" class="btn btn-primary mx-2">Продолжить</a>
        </div>
      </div>
    </div>
  </b-modal>
</div>
</template>
<script>
export default {
  name: 'Identification',
  data() {
    return {
      modalMessage: '',
      modalDesc: '',
      modalImg: '/images/profile/modal-danger.svg',
      modalSuccess: false,
      error: true,
      imageUrl: '',
      loading: false,
      cameraInitialized: false,
      takedScreenshot: false,
      takedImageSource: ''
    }
  },
  methods: {
    reset(){
      this.$router.go('identification');
    },
    captureUserMedia: (mediaConstraints, successCallback, errorCallback) => {
      navigator.mediaDevices.getUserMedia(mediaConstraints).then(successCallback).catch(errorCallback);
    },
    nextPage() {
      if (this.error){
        this.modalImg = '/images/profile/modal-danger.svg';
        this.modalMessage = 'Ошибка';
        this.modalDesc = 'Вы не подтвердили личность';
        this.$bvModal.show('error-modal');
      } else {
        this.$router.push({ path: '/about' })
      }
    },
    savePhoto() {
      let video = document.getElementById('capture-video');
      let canvas = document.getElementById('c');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
      let img = canvas.toDataURL("image/png");

      let i = document.getElementById('i');
      i.src = img;

      this.takedScreenshot = true;
      this.cameraInitialized = false;
      this.takedImageSource = img;
      localStorage.setItem('avatar-base64', img);

      // this.sendRequest(this.imgToBlob(img), video);
      this.sendRequest(img, video);
    },
    imgToBlob(img){
      let byteString;
      if (img.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(img.split(',')[1]);
      } else {
        byteString = unescape(img.split(',')[1]);
      }
      let mimeString = img.split(',')[0].split(':')[1].split(';')[0];
      let ia = new Uint8Array(byteString.length);
      for (let j = 0; j < byteString.length; j++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {type:mimeString});
    },
    sendRequest(img, video){
      const quizData = localStorage.getItem('quiz');
      let data = {};
      if (quizData) {
        data = JSON.parse(quizData)
      }
      const userId = data.user_id;

      let fd = new FormData();
      fd.append('user_id', userId);
      fd.append('file', img);

      this.loading = true;
      this.$http.post(window.MYNBALA_API_ROOT + '/api/photo/check', fd).then((res) => {
        this.loading = false;
        this.modalImg = '/images/profile/modal-success.svg';
        this.modalMessage = 'Успешно';
        this.modalDesc = res.body;
        this.modalSuccess = true;
        this.$bvModal.show('error-modal');
        this.error = false;
        localStorage.setItem('identification', 'true');
      }).catch((e) => {
        this.loading = false;
        this.modalImg = '/images/profile/modal-danger.svg';
        this.modalMessage = 'Ошибка';
        this.modalSuccess = false;
        this.modalDesc = e.body;
        this.$bvModal.show('error-modal');
        this.error = true;
        localStorage.setItem('identification', 'false');
        // console.log(e);
      });

      video.style.display = 'none';
      if (window.stream) {
        window.stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    },
    prepareCamera(){
      this.captureUserMedia(
        {
          video: {
            frameRate: 24,
          }
        }, (stream) => {
          this.cameraInitialized = true;
          window.stream = stream;
          let video = document.createElement('video');
          video.controls = false;
          video.width = 320;
          video.srcObject = stream;
          video.id = 'capture-video';
          video.setAttribute('autoplay', '');
          video.setAttribute('muted', '');
          video.setAttribute('playsinline', '');
          video.style.marginLeft = '-60px';
          video.play();

          let image = document.createElement('img');
          image.id = 'i';
          image.width = 320;
          image.style.marginLeft = '-60px';
          image.style.display = 'none';

          document.getElementById('capture').appendChild(video);
          document.getElementById('capture').appendChild(image);

        }, (e) => {
          console.error('media error', e);
        });
    },
  },
  beforeDestroy() {
    if (window.stream) {
      window.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  },
  mounted() {
    localStorage.setItem('identification', 'false');
    this.prepareCamera();
  },
}
</script>

<style>
#capture{
  overflow: hidden;
  position: relative;
}
#capture:after{
  content: " ";
  background-image: url("/images/avatar-overlay-2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 5px;
  bottom: 15px;
  left: 5px;
  right: 5px;
}
#capture:before{
  content: " ";
  background-image: url("/images/avatar-overlay.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0;
  right: 0;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.takePhotoButton{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border: none;
  font-size: 16px;
  line-height: 19px;
  color: #05458C;
  padding: 12px 18px;
}
.takePhotoButton .takePhotoIcon{
  background-image: url("/images/capture-border.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 24px 45px;
  margin: 10px 0 0;
  border-radius: 12px;
}
.sendFileButton{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  border: none;
  font-size: 16px;
  line-height: 19px;
  color: #05458C;
  padding: 20px 18px;
}
.sendFileButton .dragdrop{
  padding: 24px 45px;
  margin: 10px 0 0;
  border: 2px dashed #05458C;
  border-radius: 12px;
}
.capture-buttons-wrap{
  display: table;
}
.capture-buttons{
  padding-top: 96px;
}
.hidden{
  display: none !important;
}
.capture-wrap {
  display: block;
  width: 200px;
  overflow: hidden;
  margin: 0 auto;
}
</style>